.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 0;
  flex-grow: 1;
  position: relative;
}

.button {
  margin: 20px 0 40px;
}
