@import "../../index.module";

.container {
  background: #ffffff;
  @include screen-md {
    height: 60px;
  }
  @include screen-md-max {
    height: 40px;
  }
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
  z-index: 3;
  position: relative;
}

.left {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  width: 100%;
}

.right {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
  width: 100%;
}

.brand {
  display: block;
  @include screen-md {
    width: 300px;
  }
  @include screen-md-max {
    width: 184px;
    height: 30px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  color: #c08d5d;
  font-family: "cerebriM", serif;
  font-size: 16px;
  text-transform: uppercase;
  border: 1px solid #c08d5d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  cursor: pointer;
}

.mr5 {
  margin-right: 5px;
}
