.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 50px;
  position: relative;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: #919191;
  color: #ffffff;
  font-family: "cerebriM";
  font-size: 18px;
  position: relative;
}

.wrap {
  padding: 0 5px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  z-index: 2;
  position: relative;
}

.path {
  height: 1px;
  background: #919191;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 85%;
}

.name {
  position: absolute;
  bottom: -20px;
  line-height: 1;
  font-size: 14px;
  font-family: "cerebriL";
  color: #0b0b0b;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.active {
  background: #c08d5d;
  .name {
    font-family: "cerebriM";
  }
}
