.avatar {
  background: rgba(192, 141, 93, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.sm {
  width: 27px;
  height: 27px;
}

.md {
  width: 50px;
  height: 50px;
}
