.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px;
  background: url("../../assets/images/banner-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.pb15 {
  padding-bottom: 15px;
}

.subtitle {
  padding-bottom: 25px;
  max-width: 230px;
}
