.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 8px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
  transition: background 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  width: calc(100% - 20px);
  &:hover {
    background: #fff7f0;
  }
}

.pd12 {
  padding-bottom: 12px;
}

.pd8 {
  padding-bottom: 8px;
}

.pd9 {
  padding-bottom: 9px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.flex_start {
  justify-content: flex-start;
}

.flex_end {
  margin-left: auto;
}

.space_betweeen {
  justify-content: space-between;
}

.logo {
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 5px;
}

.mr15 {
  margin-right: 15px;
}

.width50 {
  @media (max-width: 480px) {
    width: 50%;
  }
}
