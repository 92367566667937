.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.description {
  p,
  span {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
  }
  a {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
    transition: color 0.2s ease;
    text-decoration: none;
    &:hover {
      color: #c08d5d;
    }
  }
  ul,
  ol {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
    li {
      padding-bottom: 10px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin-bottom: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
