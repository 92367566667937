.container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.copyright {
  font-size: 14px;
  color: #0b0b0b;
  font-family: "cerebriL";
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}

.title {
  margin-bottom: 10px;
}

.button {
  max-width: 300px;
  margin-top: 20px;
}
