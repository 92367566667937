.container {
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.description {
  h1,
  h2,
  p,
  span {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
  }
  a {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
    transition: color 0.2s ease;
    text-decoration: none;
    &:hover {
      color: #c08d5d;
    }
  }
  ul,
  ol {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
    li {
      padding-bottom: 10px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0 20px;
}

.button {
  max-width: 47.5%;
}
