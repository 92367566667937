.label {
  padding: 5px;
  border: 1px solid #c08d5d;
  border-radius: 5px;
  background: rgba(192, 157, 93, 0.25);
  width: max-content;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-right: 5px;
}
