.button {
  max-width: 230px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background: #ffffff;
  text-transform: uppercase;
  border: 1px solid #000000;
  -webkit-box-shadow: -3px 4px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: -3px 4px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: -3px 4px 0px 0px rgba(0, 0, 0, 1);
  outline: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  text-decoration: none;
  &:active {
    top: 2px;
    left: -1px;
    box-shadow: none;
    transition: top, box-shadow, left;
    transition-duration: 0.1s;
    transition-timing-function: linear;
  }
}
