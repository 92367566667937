.row {
  display: flex;
  flex-direction: row;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.w30 {
  width: 30%;
}

.w100 {
  width: 100%;
}

.alignItemsToEnd {
  align-items: flex-end;
}

.spaceBetween {
  justify-content: space-between;
}

.label {
  font-size: 14px;
  color: #0b0b0b;
  font-family: "cerebriM";
  margin-bottom: 3px;
  span {
    color: #c08d5d;
  }
}

.input {
  height: 35px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 2px;
  color: #0b0b0b;
  font-family: "roboto";
  font-size: 16px;
  padding: 0 8px;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  transition: box-shadow, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  &:focus {
    border: 1px solid #c08d5d;
    outline: none;
    -webkit-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
    -moz-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
    box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  }
  &::placeholder {
    color: rgba(#0b0b0b, 0.5);
    font-family: "roboto";
    font-size: 16px;
  }
}

.element {
  border: 1px solid #000000;
  border-radius: 2px;
  padding: 0 8px;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  transition: box-shadow, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.elementOnFocus {
  border: 1px solid #c08d5d;
  outline: none;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
}

.rowInMobile {
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.pb10 {
  padding-bottom: 10px;
}

.note {
  font-size: 14px;
  font-family: "cerebriL";
  color: #0b0b0b;
  padding-bottom: 40px;
  span {
    font-family: "cerebriM";
  }
}

.flexEnd {
  justify-content: flex-end;
}

.pt30 {
  padding-top: 30px;
}

.pb25 {
  padding-bottom: 25px;
}

.ml5 {
  margin-left: 5px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin-bottom: 20px;
}

.feedback {
  font-size: 16px;
  font-family: "cerebriL";
}

.error {
  color: #ff0000;
}
