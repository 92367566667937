.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
