.container {
  display: flex;
  width: 100%;
  position: relative;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;
  box-shadow: none;
  outline: none;
  background: transparent;
  flex: 1;
  cursor: pointer;
  background: #ffffff;
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  display: flex;
  width: calc(100% - 1px);
  flex-direction: column;
  align-items: flex-start;
  transform: translateY(0);
  z-index: 1;
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-bottom: 0;
  left: -1px;
  transition: all 0.3s ease;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

.show {
  transform: translateY(-100%);
  transition: all 0.3s ease;
}
