.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #ffffff;
  height: 50px;
  max-width: 800px;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.16);
  z-index: 3;
}

.wrapperDropdown {
  display: flex;
  flex: 1;
}
