.fieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
}

.inputLabel {
  padding-bottom: 2px;
  font-family: "cerebriM";
  color: #0b0b0b;
  font-size: 14px;
}

.inputContainer {
  border: 1px dashed #d7d7d7;
  height: 63px;
  position: relative;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #ffffff;
  border-radius: 2px;
  padding: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.placeholder {
  color: #0b0b0b;
  font-family: "cerebriM";
  font-size: 16;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  white-space: nowrap;
}

.focus {
  border: 1px solid #c08d5d;
  outline: none;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
}
