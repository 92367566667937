.container {
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background: transparent;
  top: 0;
  left: 0;
}

.visible {
  display: flex;
}

.modal {
  z-index: 2;
  background: #ffffff;
  max-width: 400px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 767px) {
    height: 100vh;
    max-width: 100vw;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  @media (max-width: 767px) {
    margin: 30px 20px;
  }
}

.button {
  width: 30px;
  height: 30px;
}

.wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}
