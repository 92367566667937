// mixins
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

$xs-min: 30em;
$sm-min: 48em;
$md-min: 64em;
$lg-min: 75em;

@mixin screen-xs {
  @media (min-width: $xs-min) {
    @content;
  }
}
@mixin screen-sm {
  @media (min-width: $sm-min) {
    @content;
  }
}
@mixin screen-md {
  @media (min-width: $md-min) {
    @content;
  }
}
@mixin screen-lg {
  @media (min-width: $lg-min) {
    @content;
  }
}

@mixin screen-md-max {
  @media (max-width: $md-min) {
    @content;
  }
}

@mixin screen-sm-max {
  @media (max-width: $sm-min) {
    @content;
  }
}

@mixin screen-xs-max {
  @media (max-width: $xs-min) {
    @content;
  }
}

@keyframes animation-spin {
  to {
    transform: rotate(1turn);
  }
}

// fonts
@font-face {
  font-family: "cerebriL";
  src: url("./assets/fonts/CerebriSans-Light.ttf");
}
@font-face {
  font-family: "cerebriM";
  src: url("./assets/fonts/CerebriSans-Medium.ttf");
}
@font-face {
  font-family: "roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

:global(.modalOpen) {
  overflow: hidden;
  height: 100% !important;
  width: 100% !important;
  position: fixed;
}

// reset
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
