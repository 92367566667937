.pb10 {
  padding-bottom: 10px;
}

.pt20 {
  padding-top: 20px;
}

.button {
  margin: 10px auto 20px;
}

.row {
  display: flex;
  flex-direction: row;
}

.space_between {
  justify-content: space-between;
}

.rowInMobile {
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
