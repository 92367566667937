.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
}

.inputLabel {
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
}

.input {
  height: 35px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 2px;
  color: #0b0b0b;
  font-family: "cerebriL";
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  transition: box-shadow, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  &:focus {
    border: 1px solid #c08d5d;
    outline: none;
    -webkit-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
    -moz-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
    box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  }
  &::placeholder {
    color: rgba(#0b0b0b, 0.5);
    font-family: "cerebriL";
    font-size: 16px;
  }
}
