.card {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.16);
  max-width: 240px;
  width: 100%;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: calc((100vw - 1280px) / 2.2);
  top: 40px;
  display: none;
  @media (min-width: 1280px) {
    display: flex;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  width: 100%;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.button {
  margin-top: 15px;
}

.pb5 {
  padding-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}
