.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.item {
  flex: 1;
  padding: 5px 27px 5px 10px;
  font-family: "cerebriL";
  font-size: 16px;
  color: rgb(11, 11, 11);
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  transition: background 0.1s ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  position: relative;
}

.item:hover {
  background: #fff7f0;
  transition: background 0.1s ease;
}

.selected {
  background: #fff7f0;
  transition: background 0.1s ease;
}

.title {
  flex: 1;
  padding: 5px 10px;
  font-family: "cerebriM";
  font-size: 16px;
  color: #c08d5d;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  background: rgba(0, 0, 0, 0.05);
}

.position {
  position: absolute;
  right: 10px;
}
