.textareaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
}

.textareaLabel {
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
}

.textarea {
  min-height: 295px;
  background: #f8f9fa;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  color: #0b0b0b;
  font-family: "cerebriL";
  font-size: 16px;
  display: flex;
  padding: 8px;
  resize: vertical;
  &:focus {
    outline: #c08d5d auto 1px;
  }
}

.toolbar {
  padding: 10px 0 5px;
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  border-radius: 0;
  margin-bottom: 10px;
}

.editor {
  min-height: 295px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 2px;
  color: #0b0b0b;
  font-family: "cerebriL";
  font-size: 16px;
  display: flex;
  padding: 0 8px;
  word-break: break-word;
  resize: vertical;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.51);
  transition: box-shadow, border;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.focused {
  border: 1px solid #c08d5d;
  outline: none;
  -webkit-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  -moz-box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
  box-shadow: -1px 3px 5px 0px rgba(192, 141, 93, 0.51);
}
