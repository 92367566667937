.container {
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
  margin-bottom: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 0;
  flex: 1;
}

.description {
  p,
  span {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
  }
  a {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
    transition: color 0.2s ease;
    text-decoration: none;
    &:hover {
      color: #c08d5d;
    }
  }
  ul,
  ol {
    color: #0b0b0b;
    font-family: "cerebriL";
    font-size: 16px;
    li {
      padding-bottom: 10px;
    }
  }
}
