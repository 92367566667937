.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 0;
  flex-grow: 1;
  position: relative;
}

.pb15 {
  padding-bottom: 15px;
}
